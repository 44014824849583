import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

import { SectionHeading } from "../components/headings/index"

const ContactPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo
        title="Kontakt"
        lang="sl"
        description="Mobile Adobe Lightroom Presets - Urejaj slike z enim klikom - Top kvaliteta in doživljenski dostop. 24/7 podpora."
      />

      <section className="max-w-screen-xl mx-auto px-4 py-10 sm:py-16">
        <SectionHeading title="heading10" />
        <div className="text-center mb-7 ">
          <p>
            Če imaš vprašanje ali potrebuješ pomoč me lahko kontaktiraš na:{" "}
            <br />{" "}
          </p>
          <div className="max-w-md mx-auto">
            <a
              href="mailto:cadez.lili@gmail.com"
              className="text-blue-600 underline"
            >
              cadez.lili@gmail.com
            </a>
          </div>
          <div className="max-w-md mx-auto text-center mt-4">
            <p className="mb-2">Ostale možnosti za kontakt: </p>
            <div className={`flex items-center justify-center gap-2`}>
              <a
                className="text-sm font-medium hover:opacity-70 underline text-blue-600"
                href="https://instagram.com/lilicadez"
                target="_blank"
                rel="noreferrer"
              >
                Instagram (Lili)
              </a>
              -
              <a
                className="text-sm font-medium hover:opacity-70 underline text-blue-600"
                href="https://instagram.com/influspace_agency"
                target="_blank"
                rel="noreferrer"
              >
                Instagram (InfluSpace)
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
